<script>



import Choices from "choices.js";
import { getDealApi } from '@/api/deal'
import {getBrokerageApi} from '@/api/brokerage'
import { required } from "vuelidate/lib/validators";

export default {

    emits: ['cancel'],

    props: ['deal'],
    components: {
        //flatPickr
    },

    validations: {
        report_office : {required}
    },

    

    data() {
        return {
            report_type : 'CIR_LETTER',
            additional_note : '',
            disabled_downlaod : true,
            report_url : '',
            report_office: '',
            offices : []
        }
    },

    methods: {
        report_generate() {
            this.$v.report_office.$touch()
            if (this.$v.report_office.$invalid == true) {
                return
            }
            if (this.report_type == 'CIR_LETTER' || this.report_type == 'CIR_NO_LETTER') {
                this.generate_cir()
            } else if (['MLS_Sales_VANCOUVER','MLS_Sales_VANCOUVER_COLLSPASED', 'MLS_Sales_VICTORIA'].indexOf(this.report_type) != -1) {
                this.generate_mls_sales()
            }
        },

        download_report() {
            window.open(this.report_url, '_blank');
        },

        generate_cir() {
            let data = {
                deal_id     : this.deal.order_deal_id,
                office_code : this.report_office,
                note        : this.additional_note,
                type        : this.report_type == 'CIR_LETTER'? 'CIR_WITH_NOTICE' : 'CIR_WITHOUT_NOTICE' 
            }
            getDealApi().generate_cir_report(data).then(res => {
                if (res.errCode == 0) {
                    this.report_url = res.data.save_route
                    this.disabled_downlaod = false
                    this.$alertify.message('Report was generated, please press download button to download');
                } else {
                    this.$alertify.error(" Generate CIR Failed " + res.errCode);
                }
            })

            
        },

        generate_mls_sales() {

            let rt = ""

            if (this.report_type == 'MLS_Sales_VANCOUVER_COLLSPASED') {
                rt = 'VANCOUVER_COLLSPASED'
            } else if (this.report_type == 'MLS_Sales_VANCOUVER') {
                rt = 'VANCOUVER'
            } if (this.report_type == 'MLS_Sales_VICTORIA') {
                rt = 'VICTORIA'
            }
            
            let data = {
                deal_id     : this.deal.order_deal_id,
                office_code : this.report_office,
                note        : this.additional_note,
                report_type :rt
            }
            getDealApi().generate_mls_report(data).then(res => {
                if (res.errCode == 0) {
                    this.report_url = res.data.save_route
                    this.disabled_downlaod = false
                    this.$alertify.message('Report was generated, please press download button to download');
                } else {
                    this.$alertify.error(" Generate CIR Failed " + res.errCode);
                }
            })

            
        },


        get_office_list() {
            getBrokerageApi().officeApi.list().then((res)=>{
                this.offices = []
                res.data.map((o) =>{
                    this.offices.push({
                        text  : o.name,
                        label : o.name,
                        value: o.office_code,
                    })
                })  

            })
        }
        

    },

    created() {
        this.get_office_list()
    },

    mounted() {
        new Choices('#report_type', {
            placeholderValue: "Please Select Report Type",
            searchPlaceholderValue: "This is a search placeholder",
        });
    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
                <div class="row">
                    <div class="col-md-8">
                        <div class="mb-3">
                            <label for="validationCustom01">Report Type</label>
                            <select v-model="report_type" id="report_type">
                                <option value="CIR_LETTER" >CIR Report With Notice Letter</option>
                                <option value="CIR_NO_LETTER" >CIR Report Without Notice Letter</option>
                                <option value="MLS_Sales_VANCOUVER" >Sales Report(Vancouver)</option>
                                <option value="MLS_Sales_VANCOUVER_COLLSPASED" >Collapsed Sales Report(Vancouver)</option>
                            </select>
                        </div>  
                    </div>

                    <div class="col-md-3s">
                        <div class="mb-3">
                            <label for="validationCustom01">Report Type</label>
                            <b-form-select v-model="report_office" :options="office_list" :class="{'is-invalid' : $v.report_office.error}">
                            </b-form-select>
                            <div v-if="$v.report_office.$error" class="invalid-feedback">
                              <span v-if="$v.report_office.$required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                            

                </div>
                 <!-- end row-->

                <div class="row">
                    <div class="col-md-12">
                        <div class="mb-3">
                            <label for="validationCustom01">Note:</label>
                            <textarea class="form-control" v-model="additional_note"  rows="4"  placeholder="Enter Additional Note..."></textarea>
                        </div>
                    </div>

                </div>
                 <!-- end row-->

                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-6">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button  variant="primary" type="button" @click="report_generate">Generate</b-button>
                            <b-button  variant="primary" type="button" @click="download_report" :disabled="disabled_downlaod">Download</b-button>
                        </div>
                    </div>
                    <div class="col-md-3">

                    </div>
                    
                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>