
<script>


/**
 * Transfer Dialog component
 */

 import Choices from "choices.js";

import {getDealProcessApi} from '@/api/deal/process'
import {getCashApi} from '@/api/cash'
import { required } from "vuelidate/lib/validators";


export default {

    emits: ['confirm', 'cancel'],

    props: ['deal'],
    components: {
        
    },

    validations() {
       if (this.is_interests == true) {
        return {
            interests: {required},
            total_amount :  {required},
        }
       } else {
        return {
            interests: {},
            total_amount :  {required},
        }
       }
    },



    data() {
        return {
           
            total_amount : 0,
            description  : '',
            account_list : [],
            remit_bank_id : '',
            receive_bank_id : '',
            is_interests : false,

            remit_bank_choice: {},
            recieve_bank_choice : {},
            interests : '',

            gl_entry_list : [],
        }
    },

    methods: {
        formSubmit() {

            this.$v.$touch();
            if (this.$v.$error == false) {
                this.make_transfer()
            }
        },

        make_transfer() {
            let data = {
                order_deal_no   : this.deal.order_deal_id,
                amount          : Number(this.total_amount),
                remit_bank_id   : this.remit_bank_id, 
                receive_bank_id : this.receive_bank_id,
            }

            if (this.is_interests == true) {
                data.interests = Number(this.interests)
            }

            getDealProcessApi().interest_transfer(data).then(res => {
                if (res.errCode == 0) {
                    this.$emit('confirm')
                } else {
                    this.$alertify.error("Make transfer Failed ret: "+ res.errCode);
                }
            })
        },

        onBankChanged(flag) {
            let bank_account;

            if (flag == 'remit') {
                if  (!this.remit_bank_id) {
                    return
                }
            } else if (flag == 'receive') {
                if  (!this.receive_bank_id) {
                    return
                }
            }
            this.gl_entry_list = this.gl_entry_list.filter(e => e.type != flag)

            if (flag == 'remit') {
                bank_account = this.account_list.find(e => e.value == this.remit_bank_id).account_obj
               
                this.gl_entry_list.push({
                    gl_number : bank_account.gl_account ,
                    cr_amount : -Number(this.total_amount),
                    type      : 'remit'
                })

                if (bank_account.is_trust) {
                    this.gl_entry_list.push({
                        gl_number : bank_account.gl_liability_account,
                        dr_amount : Number(this.total_amount),
                        type      : 'remit'
                    })
                } else if (bank_account.account_type == 'bankAccount_type2') {
                    this.gl_entry_list.push({
                        gl_number : '12200',
                        dr_amount : Number(this.total_amount),
                        type      : 'remit'
                    })
                }

                if (this.is_interests) {
                    this.gl_entry_list.push({
                        gl_number : bank_account.gl_account ,
                        cr_amount : -Number(this.interests),
                        type      : 'remit'
                    })

                    if (bank_account.is_trust) {
                        this.gl_entry_list.push({
                            gl_number : bank_account.gl_liability_account,
                            dr_amount : Number(this.interests),
                            type      : 'remit'
                        })
                    } else if (bank_account.account_type == 'bankAccount_type2') {
                        this.gl_entry_list.push({
                            gl_number : '12200',
                            dr_amount : Number(this.interests),
                            type      : 'remit'
                        })
                    }
                }

            } else if (flag == 'receive') {
                bank_account = this.account_list.find(e => e.value == this.receive_bank_id).account_obj

                this.gl_entry_list.push({
                    gl_number : bank_account.gl_account,
                    cr_amount : +Number(this.total_amount),
                    type      : 'receive'
                })

                if (bank_account.is_trust) {
                    this.gl_entry_list.push({
                        gl_number : bank_account.gl_liability_account,
                        dr_amount : -Number(this.total_amount),
                        type      : 'receive'
                    })
                } else if (bank_account.account_type == 'bankAccount_type2') {
                    this.gl_entry_list.push({
                        gl_number : '12200',
                        dr_amount : -Number(this.total_amount),
                        type      : 'receive'
                    })
                }

                if (this.is_interests) {
                    this.gl_entry_list.push({
                        gl_number : bank_account.gl_account,
                        cr_amount : +Number(this.interests),
                        type      : 'receive'
                    })

                    if (bank_account.is_trust) {
                        this.gl_entry_list.push({
                            gl_number : bank_account.gl_liability_account,
                            dr_amount : -Number(this.interests),
                            type      : 'receive'
                        })
                    } else if (bank_account.account_type == 'bankAccount_type2') {
                        this.gl_entry_list.push({
                            gl_number : '12200',
                            dr_amount : -Number(this.interests),
                            type      : 'receive'
                        })
                    }
                }
            }


        },


    },

    created() {
    },

    mounted() {
        this.total_amount        = this.deal.balance
        this.remit_bank_choice   = new Choices('#remit_bank_account_choice')
        this.recieve_bank_choice = new Choices('#receive_bank_account_choice')

        getCashApi().bank_account_list({}).then((res) =>{
            if (res.errCode == 0) {
                res.data.accounts.map((a)=>{
                    //Trust or Commission
                    if (a.is_trust || a.account_type == 'bankAccount_type2') {
                        this.account_list.push({
                            label       : a.name,
                            value       : a.id,
                            account     : a.a_account,
                            account_obj : a,
                            selected    : false
                        })
                        
                    }
                })

                this.remit_bank_choice.setChoices(this.account_list)
                this.recieve_bank_choice.setChoices(this.account_list)
            }

            this.account_list[0].selected = true
            this.receive_bank_id = this.account_list[0].id
            this.remit_bank_id   = this.account_list[0].id
        })
    },

   
    watch: {
        total_amount() {
            this.onBankChanged('remit')
            this.onBankChanged('receive')
        },

        interests() {
            this.onBankChanged('remit')
            this.onBankChanged('receive')
        }
    }


};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
                <div class="row">
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label>From</label>
                            <select id="remit_bank_account_choice" class="form-control" v-model="remit_bank_id"  @change="onBankChanged('remit')"></select>
                        </div>
                    </div>
                    <div class="col-md-1">
                        <div class="mb-3">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label >To</label>
                            <select id="receive_bank_account_choice" class="form-control" v-model="receive_bank_id"  @change="onBankChanged('receive')" ></select>
                        </div>
                    </div>
                   
                </div>
                <!-- end row-->

                <div class="row">
                    

                    <div class="col-md-4">
                        <div class="mb-3">
                            <label>Amount</label>
                            <input   type="text" class="form-control"  placeholder=""  v-model ="total_amount"  :class="{
                                        'is-invalid': $v.total_amount.$error,
                                    }"/>
                            <div v-if="$v.total_amount.$error" class="invalid-feedback">
                                <span v-if="!$v.total_amount.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1">
                        <div class="mb-3">
                            <label for="validationCustom01">Interests </label>
                            <input  v-model="is_interests" type="checkbox" class="form-check"  />
                           
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label>  &nbsp;</label>
                            <input   type="text" class="form-control"  placeholder=""  v-model ="interests" :disabled="!is_interests"  :class="{
                                        'is-invalid': $v.interests.$error,
                                    }"/>
                            <div v-if="$v.interests.$error" class="invalid-feedback">
                                <span v-if="!$v.interests.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end row-->


                <div class="row">
                    

                    <div class="col-md-10">
                        <div class="mb-3">
                            <label for="validationCustom01">Description </label>
                            <input  v-model="description" type="text" class="form-control"  placeholder=""  />
                           
                        </div>
                    </div>
                   
                </div>
                <!-- end row-->

               
                <div class="row">
                    <div class="table-responsive">
                        <div class="table align-middle table-nowrap">
                            <b-table :items="gl_entry_list" :fields="[{ key: 'GL' }, { key: 'CR' }, { key: 'DR' },{key : 'Action'}]"
                                responsive="sm" :per-page="gl_entry_list.length" class="table-check">

                                <template #cell(GL)="data">
                                    {{ data.item.gl_number  }} 
                                </template>
                                <template #cell(CR)="data">
                                    {{  data.item.cr_amount? '$'+data.item.cr_amount.toLocaleString() : ''}}
                                </template>
                                <template #cell(DR)="data">
                                    {{  data.item.dr_amount? '$'+data.item.dr_amount.toLocaleString() : ''}}
                                </template>
                                <template #cell(Action)="data">
                                    <b-button variant="outline-danger" size="sm" @click="onRemoveFee(data.item)" v-if="data.item.is_base_flag == false">
                                    <i class="mdi mdi-close"></i>
                                    </b-button>
                                </template>
                            </b-table>
                        </div>
                    </div>
                </div>
                <!-- end GL Row-->

                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button variant="primary" type="submit">Post Transfer</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>

                </div>
                <!-- end row-->

              


            </form>
        </div>
    </div>
    <!-- end card -->
</template>