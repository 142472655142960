<script>


/**
 * Transfer Dialog component
 */

import Choices from "choices.js";

import { getDealProcessApi } from '@/api/deal/process'
import { getCashApi } from '@/api/cash'
import { required } from "vuelidate/lib/validators";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";


export default {

    emits: ['confirm', 'cancel'],

    props: ['deal'],
    components: {
        flatPickr
    },

    validations() {

        return {
            interests: { required },
            post_date: { required },
        }

    },



    data() {
        return {

            post_date: '',
            description: '',
            account_list: [],
            bank_id: '',
            bank_choice: {},
            interests: '',
        }
    },

    methods: {
        formSubmit() {

            this.$v.$touch();
            if (this.$v.$error == false) {
                this.submit_post()
            }
        },

        submit_post() {
            let data = {
                order_deal_no: this.deal.order_deal_id,
                interests: Number(this.interests),
                bank_id: this.bank_id,
            }

            getDealProcessApi().post_interests(data).then(res => {
                if (res.errCode == 0) {
                    this.$emit('confirm')
                } else {
                    this.$alertify.error("Post Interests Failed ret: " + res.errCode);
                }
            })
        },




    },

    created() {
    },

    mounted() {

        this.bank_choice = new Choices('#bank_account_choice')

        getCashApi().bank_account_list({}).then((res) => {
            if (res.errCode == 0) {
                res.data.accounts.map((a) => {
                    //Trust or Commission
                    if (a.is_trust || a.account_type == 'bankAccount_type2') {
                        this.account_list.push({
                            label: a.name,
                            value: a.id,
                            account: a.a_account,
                            account_obj: a,
                            selected: false
                        })

                    }
                })

                this.account_list[0].selected = true
                this.bank_id = this.account_list[0].value

                this.bank_choice.setChoices(this.account_list)
            }

          
        })
    },


    watch: {

    }


};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
                <div class="row">
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label>Bank Account</label>
                            <select id="bank_account_choice" class="form-control" v-model="bank_id"></select>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label class="form-label">Post Date</label>
                            <flat-pickr class="form-control" v-model="post_date" type="text" :class="{
                                'is-invalid': $v.post_date.$error,
                            }" />
                            <div v-if="$v.post_date.$error" class="invalid-feedback">
                                <span v-if="!$v.post_date.required">This value is required.</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="mb-3">
                            <label> Interests</label>
                            <input type="text" class="form-control" placeholder="" v-model="interests" :class="{
                                'is-invalid': $v.interests.$error,
                            }" />
                            <div v-if="$v.interests.$error" class="invalid-feedback">
                                <span v-if="!$v.interests.required">This value is required.</span>
                            </div>
                        </div>
                    </div>

                </div>
                <!-- end row-->



                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button variant="primary" type="submit">Post</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>

                </div>
                <!-- end row-->




            </form>
        </div>
    </div>
    <!-- end card -->
</template>